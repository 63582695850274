import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import "./Lightbox.css";
import 'react-image-lightbox/style.css';

const MyLightbox = (props) => {
  const { isOpen, setIsOpen, images } = props;
  const [photoIndex, setPhotoIndex] = useState(0);
  return (
    <>
      {images?.length > 0 && isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
          style={{ "z-index": 1400 }}
        />
      )}
    </>
  );
};

export default MyLightbox;
