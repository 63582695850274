import React, { useEffect, useState } from "react";
import axios from "axios";
import RealizationCard from "./components/RealizationCard";

import styled from "styled-components";
import { device } from "../../theme";

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-gap: 1.6rem;
  padding-bottom: 1.6rem;

  @media all and ${device.mobileL} {
    grid-template-columns: auto auto;
  }

  @media all and ${device.laptop} {
    grid-template-columns: auto auto auto;
  }
`;

const Gallery = () => {
  const [realizations, setRealizations] = useState();

  useEffect(() => {
    let url = `${process.env.REACT_APP_API_URL}/realizations?_limit=200`;
    axios
      .get(url)
      .then((response) => {
        setRealizations(response.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      {realizations && (
        <CardsContainer>
          {realizations.map((realization, index) => (
            <RealizationCard
              key={`realization-${index}`}
              realization={realization}
            />
          ))}
        </CardsContainer>
      )}
    </>
  );
};

export default Gallery;
