import React from "react";
import ReactPaginate from "react-paginate";
import styled from "styled-components";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { device, theme } from "../../../theme";
import "./Pagination.css";

const StyledPagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 3.2rem !important;

  li {
    font-size: 1rem;
    color: ${theme.colors.lightGray};
    a {
      border-radius: 50%;
      background-color: ${theme.colors.dark};
      width: 3rem;
      height: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0.2rem;
      cursor: pointer;
      border: none !important;
    }

    *:focus {
      outline: none !important;
    }

    &.active,
    &:hover {
      color: ${theme.colors.dark};
    }

    &.active a,
    &:hover a {
      background-color: ${theme.colors.primary};
    }

    &.break-me {
      pointer-events: none;
    }
  }

  @media all and ${device.mobileL} {
    li {
      font-size: 1.6rem;
      a {
        width: 4rem;
        height: 4rem;
      }
    }
  }
`;

const PaginationIcon = styled(FontAwesomeIcon)``;

const Pagination = (props) => {
  const { pageCount, handlePageClick, activePage } = props;
  return (
    <StyledPagination>
      <ReactPaginate
        initialPage={activePage - 1}
        previousLabel={<PaginationIcon icon={faAngleDoubleLeft} />}
        nextLabel={<PaginationIcon icon={faAngleDoubleRight} />}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={pageCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={1}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
      />
    </StyledPagination>
  );
};
export default Pagination;
